// User Repository
import { User } from '@/interfaces/GlobalInterfaces.ts'
import { apiClient } from '@/services/ApiClientService.ts'

export class UserRepository {
    async getProfile(): Promise<User> {
        return apiClient.get<User>('/profile')
    }

    async updateProfile(data: Partial<User>): Promise<User> {
        return apiClient.put<User>('/profile', data)
    }
}
