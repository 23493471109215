import { User } from '@/interfaces/GlobalInterfaces'
import { sdk } from '@/repositories/sdk'
import React, { createContext, ReactNode, useContext, useState } from 'react'

interface AuthContextType {
    user: User | null
    login: (email: string, password: string) => Promise<void>
    logout: () => Promise<void>
    isAuthenticated: () => boolean
}

const AuthContext = createContext<AuthContextType | null>(null)

interface AuthProviderProps {
    children: ReactNode
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
    const [user, setUser] = useState<User | null>(null)
    // useEffect(() => {
    //     const fetchUser = async () => {
    //         const currentUser = await sdk.auth.getCurrentUser()
    //         setUser(currentUser)
    //     }
    //     fetchUser()
    // }, [])

    const login = async (email: string, password: string) => {
        await sdk.auth.login(email, password)
        const currentUser = await sdk.auth.getCurrentUser()
        setUser(currentUser)
    }

    const logout = async () => {
        await sdk.auth.logout()
        setUser(null)
    }

    const isAuthenticated = () => user !== null

    return <AuthContext.Provider value={{ user, login, logout, isAuthenticated }}>{children}</AuthContext.Provider>
}

export const useAuth = (): AuthContextType => {
    const context = useContext(AuthContext)
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider')
    }
    return context
}
