import axios, { AxiosInstance, AxiosResponse, InternalAxiosRequestConfig } from 'axios'

export class ApiClientService {
    private axiosInstance: AxiosInstance

    constructor(baseURL: string) {
        this.axiosInstance = axios.create({
            baseURL,
            headers: {
                'Content-Type': 'application/json',
            },
            withCredentials: true,
        })
    }

    public async get<T>(url: string, config?: InternalAxiosRequestConfig): Promise<T> {
        const response: AxiosResponse<T> = await this.axiosInstance.get(url, config)
        return response.data
    }

    public async post<T>(url: string, data?: unknown, config?: InternalAxiosRequestConfig): Promise<T> {
        const response: AxiosResponse<T> = await this.axiosInstance.post(url, data, config)
        return response.data
    }

    public async put<T>(url: string, data?: unknown, config?: InternalAxiosRequestConfig): Promise<T> {
        const response: AxiosResponse<T> = await this.axiosInstance.put(url, data, config)
        return response.data
    }

    public async delete<T>(url: string, config?: InternalAxiosRequestConfig): Promise<T> {
        const response: AxiosResponse<T> = await this.axiosInstance.delete(url, config)
        return response.data
    }
}

export const apiClient = new ApiClientService(import.meta.env.VITE_API_BASE_URL)
