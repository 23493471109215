import { useEffect, useState } from 'react'
import { getLocale, setLocale, setTranslations, setTranslationsGetter } from 'react-i18nify'

export type SupportedLocale = 'en-US' | 'en-GB' | 'es-ES' | 'fr-FR' | 'de-DE' | 'ja-JP' | 'debug'
export const SUPPORTED_LOCALES: SupportedLocale[] = ['en-US', 'en-GB', 'es-ES', 'fr-FR', 'de-DE', 'ja-JP', 'debug']

const LAST_FALLBACK_LOCALE: SupportedLocale = 'en-US'
const DEBUG_LOCALE: SupportedLocale = 'debug'
const LOCALE_KEY = 'userLocale' // Key for storing the user's locale in localStorage

// Define a type for the JSON import
type TranslationModule = { default: Record<string, Record<string, string>> }

const LANGUAGE_IMPORTS: Record<Exclude<SupportedLocale, 'debug'>, () => Promise<TranslationModule>> = {
    'en-US': () => import(/* webpackChunkName: "locale-en-US" */ '../locales/en-US'),
    'en-GB': () => import(/* webpackChunkName: "locale-en-GB" */ '../locales/en-GB'),
    'es-ES': () => import(/* webpackChunkName: "locale-es-ES" */ '../locales/es-ES'),
    'fr-FR': () => import(/* webpackChunkName: "locale-fr-FR" */ '../locales/fr-FR'),
    'de-DE': () => import(/* webpackChunkName: "locale-de-DE" */ '../locales/de-DE'),
    'ja-JP': () => import(/* webpackChunkName: "locale-ja-JP" */ '../locales/ja-JP'),
}

export function useLocaleEffect() {
    const [isLoaded, setIsLoaded] = useState(false)

    useEffect(() => {
        const loadTranslations = async () => {
            const locale = detectLocale() as SupportedLocale
            console.log('Loading translations for locale a:', locale)
            const translations: Record<string, Record<string, string>> = {}

            if (locale === DEBUG_LOCALE) {
                console.log('Debug mode activated, using empty translations')
                setTranslationsGetter(() => ({}))
                setIsLoaded(true)
                return
            }

            try {
                const importLocale = locale as Exclude<SupportedLocale, 'debug'>
                if (!LANGUAGE_IMPORTS[importLocale]) {
                    throw new Error(
                        `No translations available for locale: ${locale}, we only have ${SUPPORTED_LOCALES.join(', ')}`
                    )
                }

                console.log('Loading translations for locale b:', locale)
                const localeModule = await LANGUAGE_IMPORTS[importLocale]()
                Object.assign(translations, localeModule.default)
                console.log('Loaded translations:', translations)

                // If the user's locale is not an English locale, load any 'en-' prefixed locale as fallback
                if (!locale.startsWith('en-')) {
                    const enLocale = SUPPORTED_LOCALES.find(
                        (l) => l.startsWith('en-') && l !== LAST_FALLBACK_LOCALE
                    ) as Exclude<SupportedLocale, 'debug'>
                    if (enLocale) {
                        const enModule = await LANGUAGE_IMPORTS[enLocale]()
                        Object.assign(translations, enModule.default)
                    }
                }

                // Load en-US as the last-ditch fallback if it hasn't been loaded yet
                if (locale !== LAST_FALLBACK_LOCALE && !translations[LAST_FALLBACK_LOCALE]) {
                    const lastFallbackModule =
                        await LANGUAGE_IMPORTS[LAST_FALLBACK_LOCALE as Exclude<SupportedLocale, 'debug'>]()
                    Object.assign(translations, lastFallbackModule.default)
                }

                console.log('Final translations:', translations)
                setTranslations(translations)
            } catch (error) {
                console.error(`Failed to load translations for locale ${locale}:`, error)
                // Load only the last fallback (en-US) in case of an error
                const fallbackModule =
                    await LANGUAGE_IMPORTS[LAST_FALLBACK_LOCALE as Exclude<SupportedLocale, 'debug'>]()
                setTranslations(fallbackModule.default)
            } finally {
                setIsLoaded(true)
            }
        }

        loadTranslations()
    }, [getLocale()]) // Add getLocale() as a dependency

    return isLoaded
}

export function detectUserLocale(): SupportedLocale {
    const detectedLocale = detectLocale()
    console.log('Detected locale:', detectedLocale)
    setLocale(detectedLocale, true)
    return detectedLocale
}

export async function loadAndSetTranslations(locale: SupportedLocale) {
    console.log('Loading translations for locale c:', locale)
    const translations: Record<string, Record<string, string>> = {}

    if (locale === DEBUG_LOCALE) {
        console.log('Debug mode activated, using empty translations')
        setTranslationsGetter(() => ({}))
        return
    }

    try {
        const localeModule = await LANGUAGE_IMPORTS[locale as Exclude<SupportedLocale, 'debug'>]()
        Object.assign(translations, localeModule.default)

        // If the user's locale is not an English locale, load any 'en-' prefixed locale as fallback
        if (!locale.startsWith('en-')) {
            const enLocale = SUPPORTED_LOCALES.find(
                (l) => l.startsWith('en-') && l !== LAST_FALLBACK_LOCALE
            ) as Exclude<SupportedLocale, 'debug'>
            if (enLocale) {
                const enModule = await LANGUAGE_IMPORTS[enLocale]()
                Object.assign(translations, enModule.default)
            }
        }

        // Load en-US as the last-ditch fallback if it hasn't been loaded yet
        if (locale !== LAST_FALLBACK_LOCALE && !translations[LAST_FALLBACK_LOCALE]) {
            const lastFallbackModule =
                await LANGUAGE_IMPORTS[LAST_FALLBACK_LOCALE as Exclude<SupportedLocale, 'debug'>]()
            Object.assign(translations, lastFallbackModule.default)
        }

        console.log('Final translations:', translations)
        setTranslations(translations)
        return true // Return true if translations were loaded successfully
    } catch (error) {
        console.error(`Failed to load translations for locale ${locale}:`, error)
        const fallbackModule = await LANGUAGE_IMPORTS[LAST_FALLBACK_LOCALE as Exclude<SupportedLocale, 'debug'>]()
        setTranslations(fallbackModule.default)
        return false // Return false if there was an error
    }
}

export async function setUserLocale(locale: SupportedLocale): Promise<void> {
    console.log('Setting locale to', locale)
    const success = await loadAndSetTranslations(locale)
    if (success) {
        localStorage.setItem(LOCALE_KEY, locale)
        setLocale(locale, true)
        window.dispatchEvent(new Event('localeChanged'))
    } else {
        console.error('Failed to set user locale due to translation loading error')
    }
}

function detectLocale(): SupportedLocale {
    const savedLocale = localStorage.getItem(LOCALE_KEY) as SupportedLocale | null
    if (savedLocale && isSupportedLocale(savedLocale)) {
        console.log('Saved locale:', savedLocale)
        return savedLocale
    }

    if (navigator.languages && navigator.languages.length) {
        const detectedLocale = findSupportedLocale(navigator.languages)
        console.log('Detected locale:', detectedLocale)
        if (detectedLocale) return detectedLocale
    }

    if (navigator.language) {
        const detectedLocale = findSupportedLocale([navigator.language])
        console.log('Detected locale:', detectedLocale)
        if (detectedLocale) return detectedLocale
    }

    return LAST_FALLBACK_LOCALE
}

function findSupportedLocale(locales: readonly string[]): SupportedLocale | undefined {
    for (const locale of locales) {
        if (isSupportedLocale(locale)) {
            return locale
        }

        const languageCode = locale.split('-')[0]
        const matchedLocale = SUPPORTED_LOCALES.find((supportedLocale) =>
            supportedLocale.startsWith(languageCode + '-')
        )
        if (matchedLocale) {
            return matchedLocale
        }
    }
    return undefined
}

function isSupportedLocale(locale: string): locale is SupportedLocale {
    return SUPPORTED_LOCALES.includes(locale as SupportedLocale)
}

export function setDebugLocale(): void {
    setUserLocale(DEBUG_LOCALE)
}
