import React, { forwardRef } from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import Typography from '@mui/material/Typography'

// header style
const headerSX = {
    p: 2.5,
    '& .MuiCardHeader-action': { m: '0px auto', alignSelf: 'center' },
}

interface MainCardProps {
    children?: React.ReactNode
    content?: boolean
    contentSX?: object
    darkTitle?: boolean
    elevation?: number
    secondary?: React.ReactNode
    sx?: object
    title?: string | React.ReactNode
    modal?: boolean
}

function MainCard(
    { children, content = true, contentSX = {}, darkTitle, elevation, secondary, sx = {}, title }: MainCardProps,
    ref: React.Ref<HTMLDivElement>
) {
    // const theme = useTheme()

    return (
        <Card
            elevation={elevation || 0}
            ref={ref}
            sx={{
                border: '1px solid #efefef',
                ...sx,
            }}
        >
            {/* card header and action */}
            {!darkTitle && title && (
                <CardHeader
                    sx={headerSX}
                    titleTypographyProps={{ variant: 'subtitle1' }}
                    title={title}
                    action={secondary}
                />
            )}
            {darkTitle && title && (
                <CardHeader sx={headerSX} title={<Typography variant="h3">{title}</Typography>} action={secondary} />
            )}

            {/* card content */}
            {content && <CardContent sx={contentSX}>{children}</CardContent>}
            {!content && children}
        </Card>
    )
}

export default forwardRef(MainCard)
