// Auth Repository
import { apiClient } from '@/services/ApiClientService.ts'
import { User } from '@/interfaces/GlobalInterfaces.ts'

export interface LoginResponse {
    message: string
    token: string
}

export interface RegisterData {
    username: string
    email: string
    password: string
}

export class AuthRepository {
    async login(email: string, password: string): Promise<LoginResponse> {
        return apiClient.post<LoginResponse>('/login', { email, password })
    }

    async register(data: RegisterData): Promise<User> {
        return apiClient.post<User>('/register', data)
    }

    async logout(): Promise<void> {
        await apiClient.post('/logout')
    }

    async getCurrentUser(): Promise<User | null> {
        return await apiClient.get<User>('/current-user')
    }
}
