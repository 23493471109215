import { AuthRepository } from './AuthRepository.ts'
import { UserRepository } from './UserRepository.ts'

// SDK
export class SDK {
    auth: AuthRepository
    user: UserRepository

    constructor() {
        this.auth = new AuthRepository()
        this.user = new UserRepository()
    }
}

export const sdk = new SDK()
